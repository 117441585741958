.box {
  width: 100%;
  min-width: 1000px;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  left: 0;
  top: 0;
  z-index: -1;
}

.logo {
  padding-top: 48px;
  padding-left: 62px;
  display: flex;
  align-items: center;

  img {
    width: 58px;
    height: 58px;

  }

  span {
    color: #34A853;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 18px;
    display: block;
  }
}

.logo_intr {
  margin-left: 21px;
  margin-top: 6px;
  display: flex;
  align-items: center;

  span:nth-of-type(1),
  span:nth-of-type(3) {
    background: #BDC4CD;
    height: 1px;
    width: 20px;
    display: block;
  }

  span:nth-of-type(2) {
    color: #BDC4CD;
    font-size: 13px;
    font-style: normal;
    font-weight: 350;
    line-height: 32px;
    letter-spacing: 10.4px;
    margin: 0 10px;
  }
}

.content {
  flex: 1;
  color: #F9F9F9;
  margin-top: 120px;
  margin-bottom: 100px;
  padding: 0 46px;

  span {
    display: block;
    width: 100%;
  }

  .title {
    font-size: 33px;
    margin-bottom: 36px;
  }

  .intr {
    font-size: 21px;
    margin-bottom: 60px;
    line-height: 1.8;
  }

  .title_en {
    font-size: 33px;
    margin-bottom: 32.5px;
  }

  .intr_en {
    max-width: 1200px;
    font-size: 21px;
    line-height: 1.8;
  }
}

.feature {
  background-color: rgba(248, 248, 248, 0.80);
  height: 83px;
  margin-bottom: 79px;
  padding: 0 100px;

  div {
    margin: auto;
    min-width: 550px;
    max-width: 1100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 83px;
  }

  span {
    width: 180px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    display: block;
    color: #fff;
    font-size: 24px;
    background-color: #34A853;
    border-radius: 30px;
  }
}