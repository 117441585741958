.box {
  width: 100%;
  min-width: 1000px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bg {
  position: fixed;
  width: 614px;
  height: 656px;
  object-fit: cover;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.logo {
  padding-top: 39px;
  padding-left: 40px;
  display: flex;
  align-items: center;

  img {
    width: 52px;
    height: 52px;
    margin-right: 16px;
  }

  span {
    color: #606060;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.content {
  color: #F9F9F9;
  margin-top: 44px;
  padding: 0 99px 60px 222px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .content_left {
    padding-top: 28px;
    margin-right: 194px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    img {
      display: block;
      width: 550.578px;
      padding-bottom: 116px;
    }

    span:nth-of-type(1) {
      display: block;
      color: #292A44;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 70px;
      letter-spacing: 2.52px;
      margin-bottom: 10px;
    }

    span:nth-of-type(2) {
      display: block;
      color: #666;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
    }
  }

  .content_right {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    >span {
      display: block;
      padding: 0 26px 0 51px;
    }

    >span:nth-of-type(1) {
      color: #474D66;
      font-size: 58px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 4.96px;
      width: 100%;
      text-align: center;
      margin-bottom: 64px;
    }

    >span:nth-of-type(2) {
      color: #474D66;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 3.6px;
      margin-bottom: 41px;
    }

    >span:nth-of-type(3) {
      margin-bottom: 39px;
    }

    >span:nth-of-type(3),
    >span:nth-of-type(4) {
      color: #474D66;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 3.6px;
    }


    .fun {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 86px;
      width: 100%;

      >div {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 233px;
          height: 144px;
          margin-bottom: 34px;
        }

        span {
          color: #474D66;
          font-size: 30px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          letter-spacing: 2.88px;
        }
      }
    }
  }
}

.feature {
  background-color: rgba(248, 248, 248, 0.80);
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 83px;
  margin-bottom: 79px;
  padding: 0 100px;

  span {
    width: 180px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    display: block;
    color: #fff;
    font-size: 24px;
    background-color: #34A853;
    border-radius: 30px;
  }
}